import React from 'react';
import { BsInstagram, BsTwitter, BsFacebook, BsLinkedin } from 'react-icons/bs';
import { FaTiktok } from 'react-icons/fa';

const Footer = () => {
  const SocialMediaLinks = ({ image, logo, link }) => (
    <>
      <a
        href={link}
        target="_blank"
        className="flex flex-col bg-center bg-cover p-6 rounded-xl relative overflow-hidden group mx-auto"
        style={{
          backgroundImage: `url(${image})`,
        }}
        rel="noreferrer"
      >
        <div className="absolute w-full h-full bg-black/25 top-0 left-0 z-0"></div>
        <div className="absolute w-full h-full bg-green-normal/30 top-0 left-0 z-0 opacity-0 duration-150 group-hover:opacity-100"></div>
        {logo}
      </a>
    </>
  );

  return (
    <footer id="footer">
      <section id="social-media" className="bg-orange-normal">
        <div className="container mx-auto p-6">
          <p className="text-white text-lg max-w-5xl mx-auto text-center">
            Let&apos;s break down the silos and collaborate to innovate in the
            fight against food waste for a better world! <br />
            Get in touch and connect with us!
          </p>
          <div className="mx-auto grid grid-cols-2 xs:grid-cols-3 sm:grid-cols-5 max-w-2xl mt-6 gap-4">
            <SocialMediaLinks
              image={require('../assets/footer/bg-instagram.jpg')}
              link="https://www.instagram.com/in2food.project/"
              logo={<BsInstagram size={'100%'} className="text-white z-10" />}
            />
            <SocialMediaLinks
              image={require('../assets/footer/bg-twitter.jpg')}
              link="https://twitter.com/in2food_project"
              logo={<BsTwitter size={'100%'} className="text-white z-10" />}
            />
            <SocialMediaLinks
              image={require('../assets/footer/bg-facebook.jpg')}
              link="https://www.facebook.com/In2food-Project-104095541716957"
              logo={<BsFacebook size={'100%'} className="text-white z-10" />}
            />
            <SocialMediaLinks
              image={require('../assets/footer/bg-linkedin.jpg')}
              link="https://www.linkedin.com/company/in2food-project"
              logo={<BsLinkedin size={'100%'} className="text-white z-10" />}
            />
            <SocialMediaLinks
              image={require('../assets/footer/bg-tiktok.jpg')}
              link="https://vt.tiktok.com/ZSE4KSEH/"
              logo={<FaTiktok size={'100%'} className="text-white z-10" />}
            />
          </div>
        </div>
      </section>
      <section id="erasmus">
        <div className="container mx-auto px-6 py-4">
          <div className="mx-auto flex flex-col sm:flex-row items-center space-y-4 sm:space-x-4 max-w-5xl sm:space-y-0">
            <img
              src={require('../assets/footer/logo-erasmus.jpeg')}
              alt="erasmus"
              className="max-w-[12rem] object-contain"
            />
            <p className="text-xs text-gray-500">
              This project has been funded with the support of Erasmus+. The
              contents are the responsibility of the author(s). The Commission
              cannot be held responsible for any use which may be made of the
              information contained therein. Project Reference Number:
              618717-EPP-1-2020-1-ID-EPPKA2-CBHE-JP
            </p>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
