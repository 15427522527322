import React from 'react';

const ButtonDownload = ({
  link,
  children,
  className,
  textClassName,
  disabled = false,
  loading = false,
}) => {
  return (
    <a
      href={link}
      download
      className={`inline-block mx-auto bg-orange-normal border border-transparent px-7 py-1 rounded-md ${className} ${
        (loading || disabled) && 'border border-gray-400 !bg-gray-200'
      }`}
    >
      <p
        className={`font-medium text-white ${
          (loading || disabled) && 'text-gray-500'
        } ${textClassName}`}
      >
        {children}
      </p>
    </a>
  );
};

export default ButtonDownload;
