import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { RxCross2 } from 'react-icons/rx';
import NavigationLink from './NavigationLink';

const Navbar = () => {
  const [hamburgOpen, setHamburgOpen] = useState(false);
  const [navbar, setNavbar] = useState(false);

  useEffect(() => {
    const changeBackground = () => {
      if (window.scrollY >= 40) {
        setNavbar(true);
      } else {
        setNavbar(false);
      }
    };

    window.addEventListener('scroll', changeBackground);

    return () => {
      window.removeEventListener('scroll', changeBackground);
    };
  }, []);

  return (
    <>
      <nav
        className={`w-full bg-white relative z-50 ${
          navbar ? '!fixed shadow-sm' : ''
        }`}
      >
        <div className="flex flex-row justify-between items-center container mx-auto px-6 py-4">
          <NavLink to="/" className="flex flex-row items-center space-x-3">
            <img
              className="w-28 object-contain"
              src={require('../../assets/footer/logo-erasmus.jpeg')}
              alt="in2food"
            />
            <img
              className="w-32 object-contain"
              src={require('../../assets/logo/in2food-logo.png')}
              alt="in2food"
            />
          </NavLink>
          <div className="flex flex-row items-center relative">
            <ul
              className={`fixed xl:static top-0 left-0 -translate-x-[100%] ${
                hamburgOpen && '-translate-x-0'
              } flex flex-col bg-white xl:bg-transparent duration-300 ease-in-out h-full min-w-[20rem] xl:min-w-[0] py-10 xl:py-0 z-50 divide-y xl:translate-x-0 xl:divide-y-0 xl:flex-row xl:items-center`}
            >
              <li className="flex flex-row items-center justify-between px-10 py-8 xl:hidden">
                <NavLink to="/" className="flex flex-col space-y-3">
                  <img
                    className="w-28 object-contain"
                    src={require('../../assets/footer/logo-erasmus.jpeg')}
                    alt="in2food"
                  />
                  <img
                    className="w-32 object-contain"
                    src={require('../../assets/logo/in2food-logo.png')}
                    alt="in2food"
                  />
                </NavLink>
                <button
                  type="button"
                  className="ml-8"
                  onClick={() => {
                    setHamburgOpen(false);
                  }}
                >
                  <RxCross2 size={30} />
                </button>
              </li>
              <NavigationLink link="/" setHamburgOpen={setHamburgOpen}>
                Home
              </NavigationLink>
              <NavigationLink link="/programme" setHamburgOpen={setHamburgOpen}>
                Programme
              </NavigationLink>
              <NavigationLink
                link="/call-for-papers-and-presentations"
                setHamburgOpen={setHamburgOpen}
              >
                Call for Papers and Presentations
              </NavigationLink>
              {/* <NavigationLink
                link="/registration"
                setHamburgOpen={setHamburgOpen}
              >
                Registration
              </NavigationLink> */}
              <NavigationLink link="/committee" setHamburgOpen={setHamburgOpen}>
                Committee
              </NavigationLink>
              <NavigationLink
                link="/contact-us"
                setHamburgOpen={setHamburgOpen}
              >
                Contact Us
              </NavigationLink>
              {/* <NavigationLinkDropdown
                link="/call-for-papers"
                menu={[
                  {
                    name: 'Call For Papers',
                    link: '/call-for-papers/call-for-papers',
                  },
                  {
                    name: 'Scientific Committee',
                    link: '/call-for-papers/scientific-committee',
                  },
                ]}
              >
                Call For Papers
              </NavigationLinkDropdown> */}
            </ul>
            <div
              id="hamburger-button"
              className={`relative xl:hidden hamburger ${
                hamburgOpen && 'open'
              }`}
              onClick={() => {
                setHamburgOpen(!hamburgOpen);
              }}
            >
              <span className="hamburger-top"></span>
              <span className="hamburger-middle"></span>
              <span className="hamburger-bottom"></span>
            </div>
          </div>
        </div>
      </nav>
      <div
        className={`fixed xl:hidden bg-black duration-300 ease-in-out bg-opacity-0 pointer-events-none ${
          hamburgOpen && '!bg-opacity-20 !pointer-events-auto'
        } z-40 w-full h-full`}
        onClick={() => {
          setHamburgOpen(false);
        }}
      ></div>
    </>
  );
};

export default Navbar;
