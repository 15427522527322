import React from 'react';
import OrganizingCommitteeItem from './components/OrganizingCommitteeItem';
import SteeringCommitteeItem from './components/SteeringCommitteeItem';

const Committee = () => {
  return (
    <>
      <section id="organizing-committee">
        <div className="section-container py-10">
          <div className="mx-auto">
            <p className="text-orange-normal font-bold text-xl text-center">
              Organizing Committee
            </p>
            <div className="flex flex-wrap flex-row justify-center gap-4 mt-4">
              <OrganizingCommitteeItem
                title="Conference Chair"
                name="Carles Sitompul"
                desc="Parahyangan Catholic University"
                image={require('../../../src/assets/committee/organizing-committee/carles-sitompul.png')}
              />
              <OrganizingCommitteeItem
                title="Conference Co-Chair"
                name="Vrameswari Omegawati"
                desc="Parahyangan Catholic University"
                image={require('../../../src/assets/committee/organizing-committee/vrameswari-omega.png')}
              />
              <OrganizingCommitteeItem
                title="Secretary & Treasurer"
                name="Clara Theresia"
                desc="Parahyangan Catholic University"
                image={require('../../../src/assets/committee/organizing-committee/clara-theresia.jpg')}
              />
              <OrganizingCommitteeItem
                title="Program & Paper Chair"
                name="Giovano Alberto"
                desc="Parahyangan Catholic University"
                image={require('../../../src/assets/committee/organizing-committee/giovano-alberto.png')}
              />
              <OrganizingCommitteeItem
                title="Social Chair"
                name="Icha Wiharani"
                desc="Parahyangan Catholic University"
                image={require('../../../src/assets/committee/organizing-committee/icha-wiharani.jpeg')}
              />
              <OrganizingCommitteeItem
                title="Documentation & Logistics Chair"
                name="Daniel Siswanto"
                desc="Parahyangan Catholic University"
                image={require('../../../src/assets/committee/organizing-committee/daniel-siswanto.jpeg')}
              />
            </div>
          </div>
        </div>
      </section>
      <section id="steering-committee">
        <div className="section-container py-10">
          <div className="mx-auto">
            <p className="text-orange-normal font-bold text-xl text-center">
              Steering Committee
            </p>
            <div className="flex flex-wrap flex-row justify-center gap-4 mt-4">
              <SteeringCommitteeItem
                name="Johanna Renny Octavia"
                desc="Parahyangan Catholic University, Indonesia"
                image={require('../../../src/assets/committee/steering-committee/johanna-renny-octavia.jpg')}
              />
              <SteeringCommitteeItem
                name="Elina Närvänen"
                desc="Tampere University, Finland"
                image={require('../../../src/assets/committee/steering-committee/elina-narvanen.png')}
              />
              <SteeringCommitteeItem
                name="Nina Mesiranta"
                desc="Tampere University, Finland"
                image={require('../../../src/assets/committee/steering-committee/nina-mesiranta.png')}
              />
              <SteeringCommitteeItem
                name="Jelle Saldien"
                desc="Ghent University, Belgium"
                image={require('../../../src/assets/committee/steering-committee/jelle-saldien.png')}
              />
              <SteeringCommitteeItem
                name="Anna de Visser-Amundson"
                desc="Hotelschool the Hague, the Netherlands"
                image={require('../../../src/assets/committee/steering-committee/anna-de-visser-amundson.png')}
              />
              <SteeringCommitteeItem
                name="Diah Wihardini"
                desc="Bina Nusantara University, Indonesia"
                image={require('../../../src/assets/committee/steering-committee/diah-wihardini.jpg')}
              />
              <SteeringCommitteeItem
                name="Stevanus Winus Wijaya"
                desc="Prasetiya Mulya University, Indonesia"
                image={require('../../../src/assets/committee/steering-committee/stevanus-wisnu-wijaya.png')}
              />
              <SteeringCommitteeItem
                name="Eddy Yusuf"
                desc="Pembangunan Jaya University, Indonesia"
                image={require('../../../src/assets/committee/steering-committee/eddy-yusuf.png')}
              />
              <SteeringCommitteeItem
                name="Mustika Tarra"
                desc="Ma Chung University, Indonesia"
                image={require('../../../src/assets/committee/steering-committee/mustika-tarra.png')}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Committee;
