import React from 'react';

const ImportantDatesItem = ({ title, date }) => {
  return (
    <div className="text-center">
      <p>{title}</p>
      <p className="font-semibold text-orange-normal text-lg">{date}</p>
    </div>
  );
};

export default ImportantDatesItem;
