import React from 'react';
import { useNavigate } from 'react-router-dom';
// import PulseLoader from 'react-spinners/PulseLoader';

const ButtonPrimary = ({
  link,
  children,
  className,
  textClassName,
  type,
  refresh = false,
  func,
  disabled = false,
  loading = false,
}) => {
  const navigate = useNavigate();

  return (
    <button
      disabled={loading || disabled}
      className={`bg-orange-normal flex flex-row border border-transparent items-center justify-center space-x-3 px-7 py-1 rounded-md ${className} ${
        (loading || disabled) && 'border border-gray-400 !bg-gray-200'
      }`}
      type="submit"
      onClick={() => {
        if (func) {
          func();
        }
        if (link) {
          navigate(link);
          if (refresh) {
            navigate(0);
          }
        }
      }}
    >
      <p
        className={`font-medium text-white ${
          (loading || disabled) && 'text-gray-500'
        } ${textClassName}`}
      >
        {children}
      </p>
      {/* <PulseLoader
        color={'hsla(183, 100%, 35%, 1)'}
        loading={loading}
        size={7}
        aria-label="Loading Spinner"
        data-testid="loader"
      /> */}
    </button>
  );
};

export default ButtonPrimary;
