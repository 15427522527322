import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import ButtonPrimary from '../../components/ButtonPrimary';
import FormInputField from '../../components/Form/FormInputField';
import FormInputFieldTextArea from '../../components/Form/FormInputFieldTextArea';
import axios from 'axios';
import { CONTACT_US, OPTIONS_WITHOUT_TOKEN } from '../../utils/endpoint';
import { useNavigate } from 'react-router-dom';

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [success, setSuccess] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setLoading(true);

    axios
      .post(CONTACT_US, data, OPTIONS_WITHOUT_TOKEN)
      .then(() => {
        setSuccess('Your message has been sent');
        setTimeout(() => {
          navigate(0);
        }, 2000);
      })
      .catch((err) => {
        setError(err.message);
        setTimeout(() => {
          setError();
        }, 6000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <section>
      <div className="section-container py-12">
        <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row max-w-6xl mx-auto sm:space-x-3">
          <div className="flex flex-col flex-[1]">
            <p className="text-green-normal font-bold text-2xl">Contact Us</p>
            <p className="font-semibold mt-3 text-lg">Johanna Renny Octavia</p>
            <p className="text-sm text-gray-400">
              Primary Coordinator of ERASMUS+ IN2FOOD Consortium
            </p>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col space-y-4 flex-[1]"
          >
            <FormInputField
              register={register}
              errors={errors}
              name="name"
              placeholder="Your Name"
              required
            />
            <FormInputField
              register={register}
              errors={errors}
              name="email"
              placeholder="Your Email"
              required
            />
            <FormInputField
              register={register}
              errors={errors}
              name="subject"
              placeholder="Subject"
              required
            />
            <FormInputFieldTextArea
              register={register}
              errors={errors}
              name="message"
              placeholder="Your Message"
              type="textarea"
              required
            />

            {success ? (
              <p className="text-green-500 text-sm border border-green-400 bg-green-50 rounded-lg px-3 py-2">
                {success}
              </p>
            ) : null}
            {error ? (
              <p className="text-red-400 text-sm border border-red-400 bg-red-50 rounded-lg px-3 py-2">
                {error}
              </p>
            ) : null}
            <ButtonPrimary className="py-2" loading={loading}>
              Submit
            </ButtonPrimary>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
