import React from 'react';
import ButtonDownload from '../../components/ButtonDownload';
import KeynoteSpeakerItem from './components/KeynoteSpeakerItem';

const Programme = () => {
  return (
    <>
      <section id="keynote-speaker">
        <div className="section-container py-10">
          <div className="max-w-6xl mx-auto">
            <p className="text-orange-normal font-bold text-xl text-center">
              Keynote Speakers
            </p>
            <div className="grid grid-cols-1 3xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-5 gap-4 mt-4">
              <KeynoteSpeakerItem
                name="Prof. Elina Närvänen"
                desc="Tampere University, Finland"
                image={require('../../../src/assets/home/speaker/elina-narvanen.jpg')}
              />
              <KeynoteSpeakerItem
                name="Prof. dr. Lieven De Marez"
                desc="Ghent University, Belgium"
                image={require('../../../src/assets/home/speaker/lieven-de-marez.png')}
              />
              <KeynoteSpeakerItem
                name="Dr. (Cand) Anna de Visser Amundson"
                desc="Hotelschool The Hague, The Netherlands"
                image={require('../../../src/assets/home/speaker/anna-de-visser-amundson.png')}
              />
              <KeynoteSpeakerItem
                name="Dr. Thedy Yogasara"
                desc="Parahyangan Catholic University, Indonesia"
                image={require('../../../src/assets/home/speaker/thedy-yogasara.jpeg')}
              />
              <KeynoteSpeakerItem
                name="Dr. Pius Sugeng Prasetyo"
                desc="Parahyangan Catholic University, Indonesia"
                image={require('../../../src/assets/home/speaker/pius-sugeng-prasetyo.png')}
              />
            </div>
          </div>
        </div>
      </section>
      <section id="rundown">
        <div className="section-container py-10">
          <p className="text-orange-normal font-bold text-xl text-center">
            Programme Day 1, 14 August 2023
          </p>
          <img
            className="mt-4 mx-auto"
            src={require('../../../src/assets/rundown/rundown-1.png')}
            alt=""
          />
        </div>
        <div className="section-container py-10">
          <p className="text-orange-normal font-bold text-xl text-center">
            Programme Day 2, 15 August 2023
          </p>
          <img
            className="mt-4 mx-auto"
            src={require('../../../src/assets/rundown/rundown-2.png')}
            alt=""
          />
        </div>
        <div className="section-container py-10 flex items-center flex-col">
          <p className="text-orange-normal font-bold text-xl text-center">
            Download the full programme here.
          </p>
          <ButtonDownload
            textClassName="text-lg"
            className="mt-4"
            link={require('../../../src/assets/rundown/rundown.pdf')}
          >
            Download
          </ButtonDownload>
        </div>
      </section>
    </>
  );
};

export default Programme;
