export const base_url = 'https://api.in2food-project.net/';

export const CONTACT_US = base_url + 'contactus';

export const OPTIONS_WITHOUT_TOKEN = () => {
  return {
    headers: {
      'Content-Type': 'application/json',
    },
  };
};
