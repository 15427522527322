import React from 'react';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer';
import Router from './components/Router';

function App() {
  return (
    <>
      <Navbar />
      <img
        className="w-full object-cover h-[20rem] hidden xxs:block"
        src={require('./assets/home/banner-speaker.png')}
        alt=""
      />
      <img
        className="w-full object-cover xxs:hidden"
        src={require('./assets/home/banner-speaker-mobile.png')}
        alt=""
      />
      <div className="flex flex-col flex-[1]">
        <Router />
      </div>
      <Footer />
    </>
  );
}

export default App;
