import React from 'react';
import { NavLink } from 'react-router-dom';

const NavigationLink = ({ children, link, setHamburgOpen }) => {
  return (
    <li className="py-4 px-10 xl:py-0 xl:px-0 xl:ml-5">
      <NavLink
        to={link}
        className={({ isActive }) =>
          `text-sm ${
            isActive
              ? 'text-white bg-orange-normal px-5 py-1 rounded-full font-semibold'
              : 'hover:text-orange-normal hover:duration-150'
          }`
        }
        onClick={() => {
          setHamburgOpen(false);
        }}
      >
        {children}
      </NavLink>
    </li>
  );
};

export default NavigationLink;
