import React from 'react';

const ConferenceFeeItem = ({ price, job }) => {
  return (
    <div className="border border-green-normal p-4 rounded-lg text-center">
      <p className="font-semibold text-orange-normal text-lg">{price}</p>
      <p>{job}</p>
    </div>
  );
};

export default ConferenceFeeItem;
