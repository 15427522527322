import React from 'react';

const KeynoteSpeakerItem = ({ name, desc, image }) => {
  return (
    <div className="flex flex-col rounded-lg overflow-hidden border border-gray-300">
      <img
        src={image}
        alt=""
        className="h-64 3xs:h-52 object-cover w-full object-top 3xs:object-center"
      />
      <div className="flex flex-col p-4 text-center space-y-1 items-center justify-center">
        <p className="font-semibold text-orange-normal leading-5">{name}</p>
        <p className="text-xs">{desc}</p>
      </div>
    </div>
  );
};

export default KeynoteSpeakerItem;
