import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CallForPapersAndPresentations from '../pages/call-for-paper-and-presentations/CallForPapersAndPresentations';
import Committee from '../pages/committee/Committee';
import ContactUs from '../pages/contact-us/ContactUs';
import Home from '../pages/home/Home';
import Programme from '../pages/programme/Programme';
// import Registration from '../pages/Registration';
import ScrollToTop from '../hooks/ScrollToTop';

const Router = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/programme" element={<Programme />} />
        {/* <Route path="/registration" element={<Registration />} /> */}
        <Route path="/committee" element={<Committee />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route
          path="/call-for-papers-and-presentations"
          element={<CallForPapersAndPresentations />}
        />
        {/* <Route path="/call-for-papers">
        <Route path="call-for-papers" element={<CallForPapers />} />
        <Route path="scientific-committee" element={<CallForPapers />} />
      </Route> */}
      </Routes>
    </>
  );
};

export default Router;
