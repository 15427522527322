import React from 'react';
import ConferenceFeeItem from './components/ConferenceFeeItem';
import ImportantDatesItem from './components/ImportantDatesItem';

const CallForPapersAndPresentations = () => {
  return (
    <>
      <section id="topics" className="section-container py-10">
        <div>
          <p>
            The conference accepts abstracts and full papers on the topics
            listed below, but not limited to:
          </p>
          <div className="flex flex-col sm:flex-row border border-green-normal p-6 rounded-2xl mt-4">
            <ul className="list-disc list-inside space-y-2 flex-[1]">
              <li>Sustainability</li>
              <li>Sustainable development goals</li>
              <li>Food waste management</li>
              <li>Design for sustainability</li>
              <li>Human and social sciences</li>
              <li>Human factors design</li>
              <li>Education for sustainable development</li>
              <li>Circular economy</li>
            </ul>
            <ul className="list-disc list-inside space-y-2 flex-[1]">
              <li>Responsible production and consumption</li>
              <li>Innovation and innovation management</li>
              <li>Energy management</li>
              <li>Sustainable urban design</li>
              <li>Service management for sustainability</li>
              <li>Hospitality and food waste management</li>
              <li>Global issues on sustainability</li>
              <li>Technology management</li>
            </ul>
          </div>
        </div>
      </section>
      <section id="submit-your-paper">
        <div className="section-container pt-0 py-10 flex flex-col items-center">
          <p
            className="text-center font-bold text-xl text-orange-normal
            before:relative before:content-[''] before:inline-block before:w-1/2 before:align-middle before:right-4 before:-ml-[50%] before:h-[1px] before:bg-orange-normal
            after:relative after:content-[''] after:inline-block after:w-1/2 after:align-middle after:left-4 after:-mr-[50%] after:h-[1px] after:bg-orange-normal
            "
          >
            Submit Your Paper
          </p>
          <a
            href="https://journal.unpar.ac.id/index.php/ICoHDES/"
            target="_blank"
            rel="noreferrer"
            className="block mt-6 bg-orange-normal rounded-md text-white p-2 px-5 text-center"
          >
            Click Here to Submit Your Paper
          </a>
        </div>
      </section>
      <section id="important-dates" className="bg-orange-verySoft">
        <div className="section-container py-10">
          <p className="text-center font-bold text-xl text-orange-normal">
            Important Dates
          </p>
          <div className="flex flex-row flex-wrap mt-10 gap-6 sm:gap-12 items-center justify-center">
            <ImportantDatesItem
              title="Abstract/Full paper submission"
              date="1 June 2023"
            />
            <ImportantDatesItem
              title="Acceptance notification"
              date="15 June 2023"
            />
            <ImportantDatesItem
              title="Registration and payment"
              date="1 July 2023"
            />
          </div>
          <div className="mt-5 text-sm text-orange-normal p-3 rounded-md border border-orange-300">
            *All submissions will be reviewed, and those that are accepted and
            presented will be published in the ICoHDES proceeding. Selected
            papers will be submitted to Scopus- indexed proceedings and
            journals, and Indonesian Sinta-accredited journals for special
            editions. There may be article processing fee for the selected
            papers.
          </div>
        </div>
      </section>
      <section id="conference-fee">
        <div className="section-container py-10">
          <p className="text-center font-bold text-xl text-orange-normal">
            Conference Fee
          </p>
          <div className="flex flex-row flex-wrap mt-10 gap-6 items-center justify-center">
            <ConferenceFeeItem
              price="1.500.000 IDR / 100 USD"
              job="Presenters"
            />
            <ConferenceFeeItem
              price="750.000 UDR / 50 USD"
              job="Participants"
            />
            <ConferenceFeeItem price="750.000 IDR / 50 USD" job="Students" />
            <ConferenceFeeItem
              price="500.000 IDR / 30 USD"
              job="Conference Dinner"
            />
            <ConferenceFeeItem
              price="Free"
              job="Members of the IN2FOOD consortium"
            />
          </div>
          <div className="mt-6 flex justify-center">
            <div className="text-sm text-orange-normal p-3 rounded-md border border-orange-300">
              *Excluding Article Processing Charges (APC)
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CallForPapersAndPresentations;
