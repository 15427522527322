import React from 'react';

const FormInputField = ({
  register,
  name,
  placeholder,
  type = 'text',
  required = false,
  errors,
}) => {
  return (
    <>
      <input
        type={type}
        {...register(name, { required: required })}
        className={`border rounded-md p-3 outline-0 focus:border-green-normal ${
          errors[name] ? '!border-red-400' : 'border-gray-200'
        }`}
        placeholder={placeholder}
      />
      {errors[name] ? (
        <span className="text-red-400 !mt-1 text-sm">{name} is required</span>
      ) : null}
    </>
  );
};

export default FormInputField;
