import React from 'react';

const VenueItem = ({ image, children }) => {
  return (
    <div className="bg-green-normal w-full sm:flex-initial sm:w-[17.2rem] rounded-lg overflow-hidden">
      <img src={image} alt="" className="rounded-lg h-48 object-cover w-full" />
    </div>
  );
};

export default VenueItem;
