import React from 'react';

const SteeringCommitteeItem = ({ name, desc, image }) => {
  return (
    <div className="flex flex-col rounded-lg overflow-hidden border border-gray-300 w-52">
      <img src={image} alt="" className="h-52 object-cover object-top" />
      <div className="flex flex-col p-4 text-center items-center justify-center">
        <p className="font-semibold text-orange-normal leading-5">{name}</p>
        <p className="text-xs mt-2">{desc}</p>
      </div>
    </div>
  );
};

export default SteeringCommitteeItem;
