import React from 'react';
import VenueItem from './components/VenueItem';

const Home = () => {
  return (
    <>
      <section id="header">
        <div className="section-container py-10">
          <p className="text-justify">
            <span className="text-orange-normal font-bold">
              Erasmus+ IN2FOOD Consortium
            </span>{' '}
            in collaboration with Center for Ergonomics Parahyangan Catholic
            University (UNPAR) organize an international interdisciplinary
            conference as part of the dissemination program of the consortium (
            <a
              href="https://in2food-project.net/"
              target="_blank"
              rel="noreferrer"
              className="regular-link text-green-normal"
            >
              https://in2food-project.net/
            </a>
            ), which aims to resolve societal challenges, particularly food
            waste issues, through education and research fostering
            interdisciplinary and collaborative innovation. The conference
            focuses on the resolution of sustainability issues, such as food
            waste, that human factors, design, and education can address. This
            conference seeks to bring together academics, practitioners,
            governments, communities, and media in a joint effort to find
            solutions for sustainability and other societal issues.
          </p>
        </div>
      </section>
      <section id="venue" className="bg-orange-verySoft">
        <div className="section-container py-10">
          <p className="text-center font-bold text-xl text-orange-normal">
            Venue
          </p>
          <div className="flex flex-col sm:flex-wrap sm:flex-row gap-4 mx-auto items-center justify-center mt-4">
            <VenueItem
              image={require('../../../src/assets/home/venue/auditorium.png')}
            ></VenueItem>
            <VenueItem
              image={require('../../../src/assets/home/venue/lapangan-hijau.png')}
            ></VenueItem>
            <VenueItem
              image={require('../../../src/assets/home/venue/lecture-theatre.png')}
            ></VenueItem>
            <VenueItem
              image={require('../../../src/assets/home/venue/ppag-atas.png')}
            ></VenueItem>
            <VenueItem
              image={require('../../../src/assets/home/venue/ppag-depan.png')}
            ></VenueItem>
          </div>
          <div className="w-full rounded-lg mt-4 p-4 text-center text-orange-normal border border-green-normal font-medium space-y-2 sm:space-y-0 mx-auto">
            <p>Arntz-Geise Learning Center (Pusat Pembelajaran Arntz-Geise)</p>
            <p>Parahyangan Catholic University (UNPAR)</p>
            <p>Ciumbuleuit 94, Bandung, Indonesia</p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
