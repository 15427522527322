import React from 'react';

const FormInputFieldTextArea = ({
  register,
  name,
  placeholder,
  errors,
  required = false,
}) => {
  return (
    <>
      <textarea
        {...register(name, { required: required })}
        className={`border rounded-md p-3 outline-0 focus:border-green-normal ${
          errors[name] ? '!border-red-400' : 'border-gray-200'
        }`}
        placeholder={placeholder}
      />
      {errors[name] ? (
        <span className="text-red-400 !mt-1 text-sm">{name} is required</span>
      ) : null}
    </>
  );
};

export default FormInputFieldTextArea;
